import { useEffect, useRef, useState } from "react";
import {
  MINUTES_IN_ONE_HOUR,
  SECONDS_IN_ONE_HOUR,
  SECONDS_IN_ONE_MINUTE,
} from "../Questionnaire.constants";

/**
 * This function takes a duration in minutes and calculates the equivalent number of hours, remaining
 * minutes after extracting hours, and remaining seconds after extracting both hours and minutes. It then
 * returns an object with these calculated time components.
 * @param durationInMinutes - The duration to be converted, represented in minutes.
 * @returns {Object} An object containing separate properties for hours, minutes, and seconds.
 */
const getTimerState = (durationInMinutes: number) => {
  const days = Math.floor(durationInMinutes / (MINUTES_IN_ONE_HOUR * 24));
  const hours = Math.floor(durationInMinutes / MINUTES_IN_ONE_HOUR);
  const minutes = Math.floor(durationInMinutes - hours * MINUTES_IN_ONE_HOUR);
  const seconds = Math.floor(
    durationInMinutes * SECONDS_IN_ONE_MINUTE -
      minutes * SECONDS_IN_ONE_MINUTE -
      hours * SECONDS_IN_ONE_HOUR,
  );
  return { days, hours, minutes, seconds };
};

// used in
// - exly-react-component-lib/useAssessment
/**
 *
 * @param totalDurationInSeconds - The total duration of the countdownTimer in seconds.
 * @param onTimeUp - Callback function that is called when the countdown reaches zero.
 * @param onChange - Callback function that is called with the time taken in minutes whenever the timer updates.
 * @param autoStart - Boolean that indicates if the timer should start automatically upon initialization.
 * @returns  {Object} An object containing functions to control the timer (`pause`, `resume`, `reset`, `start`) and the current state of the timer (`countdownTimer`).
 */
const useCountdownTimer = ({
  totalDurationInSeconds = 0,
  onTimeUp,
  onChange,
  autoStart = false,
}: {
  totalDurationInSeconds?: number;
  onTimeUp?: () => void;
  onChange?: (timeTaken: number) => void;
  autoStart?: boolean;
}) => {
  const initialTimerState = getTimerState(
    totalDurationInSeconds / SECONDS_IN_ONE_MINUTE,
  );

  const [countdownTimer, setCountdownTimer] = useState(initialTimerState);
  const [duration, setDuration] = useState(totalDurationInSeconds);
  const [isRunning, setIsRunning] = useState(autoStart);

  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  // TODO: @ritika to be optimised to avoid rerender every second
  let timer = duration;

  const pause = () => {
    setIsRunning(false);
  };

  const reset = () => {
    setDuration(totalDurationInSeconds);
    setCountdownTimer(initialTimerState);
    setIsRunning(false);
    timer = totalDurationInSeconds;
    clearInterval(intervalRef.current!);
  };

  const start = () => {
    setDuration(totalDurationInSeconds);
    setCountdownTimer(initialTimerState);
    setIsRunning(true);
    timer = totalDurationInSeconds;
  };

  const resume = () => {
    const newDuration =
      countdownTimer.hours * SECONDS_IN_ONE_HOUR +
      countdownTimer.minutes * SECONDS_IN_ONE_MINUTE +
      countdownTimer.seconds;
    setDuration(newDuration);
    setIsRunning(true);
  };

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        timer -= 1;
        if (timer < 0) {
          if (onTimeUp) onTimeUp();
          reset();
        } else {
          const totalTimeTaken = (duration - timer) / SECONDS_IN_ONE_MINUTE;
          if (onChange) onChange(totalTimeTaken);
          setCountdownTimer({
            ...getTimerState(timer / SECONDS_IN_ONE_MINUTE),
          });
        }
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [isRunning]);

  return {
    pause,
    resume,
    reset,
    start,
    countdownTimer,
  };
};

export default useCountdownTimer;
