import axios, { AxiosRequestConfig } from "axios";
import { isRequestSuccessful } from "../../../../../utils/common/common";
import { checkIsObjectEmpty } from "../../../../../utils/common/dataTypes/object";
import { checkIsStringEmpty } from "../../../../../utils/common/dataTypes/string";
import { API_METHODS } from "../../Api/constants/Api.constants";
import { queryParamsStringify } from "../../Api/utils/APi.utils";
import {
  EXPIRY_TIME,
  GET_SIGNED_URL_API,
  url_required_for_keys,
} from "../constants/S3.constants";
import { IS3 } from "../S3.interfaces";

/**
 * get signed url from s3
 * @returns signed url of a file from s3
 */
export const getS3SignedUrl = async ({
  bucket,
  key,
  expiresIn = EXPIRY_TIME,
  lamdaBaseURL,
  authorizationToken,
}: IS3.IGetSignedUrl): Promise<string> => {
  const payload = {
    key,
    url_required_for: url_required_for_keys.GET,
    expirytime: expiresIn,
    bucket_name: bucket,
  };
  const queryUrl = `${lamdaBaseURL}/${GET_SIGNED_URL_API}?${queryParamsStringify(
    payload,
  )}`;

  const options = {
    method: API_METHODS.GET,
    headers: {
      authorizationToken,
    },
  };
  const response = await fetch(queryUrl, options);
  const url = await response.json();
  return url;
};

/**
 * lamda function to upload file to s3
 * @returns Loaction url of the file uploaded if getFileLocation param is provided
 */
export const uploadToS3 = async ({
  bucket,
  key,
  file,
  isFilePublic = false,
  lamdaBaseURL,
  authorizationToken,
  abortSignal,
  onProgress,
  onAbort,
  contentType,
}: IS3.IUploadToS3Params) => {
  try {
    const payload: IS3.IUploadPayload = {
      key,
      url_required_for: url_required_for_keys.UPLOAD,
      expirytime: EXPIRY_TIME,
      bucket_name: bucket,
      content_type: contentType ?? file.type,
    };

    if (isFilePublic) {
      payload.is_public_read = isFilePublic;
    }

    const queryUrl = `${lamdaBaseURL}/${GET_SIGNED_URL_API}?${queryParamsStringify(
      payload,
    )}`;

    const options = {
      method: API_METHODS.GET,
      headers: {
        authorizationToken,
      },
    };
    const response = await fetch(queryUrl, options);
    const uploadObj = await response.json();

    if (
      checkIsStringEmpty(uploadObj?.url) ||
      checkIsObjectEmpty(uploadObj?.fields)
    ) {
      throw new Error("Invalid upload object received from lamda");
    }

    const formData = new FormData();

    Object.keys(uploadObj.fields).forEach((field) => {
      formData.append(field, uploadObj.fields[field]);
    });
    formData.append("file", file);

    const uploadOptions: AxiosRequestConfig = {
      headers: {
        authorizationToken,
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total && progressEvent.loaded) {
          if (onProgress) {
            onProgress({
              total: progressEvent.total,
              loaded: progressEvent.loaded,
            });
          }
        }
      },
    };

    if (abortSignal) {
      uploadOptions.signal = abortSignal;
    }

    const uploadResponse = await axios({
      ...uploadOptions,
      method: "post",
      url: uploadObj?.url,
      data: formData,
    });
    if (isRequestSuccessful(uploadResponse.status)) {
      return {
        // encode url to avoid bugs due to special characters in url
        Location: encodeURI(`${uploadObj?.url}${key}`),
        Bucket: bucket,
        Key: key,
      };
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      if (onAbort) onAbort();
    } else {
      throw error;
    }
  }
};

export const extractS3KeyFromAWSUrl = (url: string) =>
  decodeURIComponent(url.split("amazonaws.com/")[1] || "");

/**
 * Extracts the bucket name from a given AWS S3 URL.
 * The bucket name is the string between "https://" and ".s3" in the URL.
 *
 * @param {string} s3Url - The AWS S3 URL from which to extract the bucket name.
 * @returns {string | null} - The extracted bucket name, or null if the URL is invalid or the pattern doesn't match.
 */
export const getS3BucketName = (s3Url: string): string | null => {
  try {
    const match = s3Url.match(/^https:\/\/(.*?)\.s3/);
    return match ? match[1] : null; // Returns the bucket name or null if not found
  } catch (error) {
    return null;
  }
};
